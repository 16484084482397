var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "tooltip font-bold border-1 rounded-lg transition-all outline-none inline-flex justify-center items-center gap-2",
    class: [_vm.buttonSize, _vm.buttonType, _vm.additionalClass, {
      disabled: _vm.disabled
    }],
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.handleClick,
      "focus": _vm.handleFocus,
      "mouseleave": function mouseleave($event) {
        return _vm.handleMousEvent(false);
      },
      "mouseenter": function mouseenter($event) {
        return _vm.handleMousEvent(true);
      }
    }
  }, [_vm.tooltip.show ? _c('span', {
    staticClass: "tooltiptext font-normal text-xs"
  }, [_vm._v(_vm._s(_vm.tooltip.text))]) : _vm._e(), _vm.icon ? _c('span', {
    class: "flex-shrink-0 ".concat(_vm.disabled ? '' : 'hover:opacity-50')
  }, [_c(_vm.icon, {
    tag: "component",
    attrs: {
      "height": _vm.forIcon.height,
      "width": _vm.forIcon.width,
      "color": _vm.forIcon.color
    }
  })], 1) : _vm._e(), _vm._v(_vm._s(_vm.buttonText) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }