var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', {
    staticClass: "py-50 px-48"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', [_c('p', {
    staticClass: "text-2xl font-bold mb-4"
  }, [_vm._v("Step 1: Select Background")]), _c('div', {
    staticClass: "border border-neutral-250 rounded-lg px-4 pt-4 pb-6 mb-5"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('div', {
    staticClass: "w-1/2"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Search File (PNG)")]), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "enterKeyAction": _vm.getGallery,
      "withIcon": "",
      "borderEnabled": "",
      "placeholder": "Input file name here..."
    },
    model: {
      value: _vm.query,
      callback: function callback($$v) {
        _vm.query = $$v;
      },
      expression: "query"
    }
  }), _c('div', {
    staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
  }, [_c('Search', {
    attrs: {
      "color": "#58595B"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "flex items-center h-full ml-3 mt-4"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Search",
      "type": "secondary",
      "additionalClass": "h-11"
    },
    on: {
      "action": _vm.getGallery
    }
  })], 1)])]), _c('div', {
    staticClass: "border border-neutral-250 rounded-lg px-4 pt-6 pb-5"
  }, [_vm.fileList.length > 0 ? _c('div', [_c('div', {
    staticClass: "grid grid-cols-3 gap-3"
  }, _vm._l(_vm.fileList, function (file, index) {
    return _c('div', {
      key: index,
      staticClass: "cursor-pointer rounded-lg",
      class: _vm.selectedFile.id === file.id ? 'border-yellow border-2' : 'border-neutral-250 border',
      on: {
        "click": function click($event) {
          return _vm.clickFile(file);
        }
      }
    }, [_c('div', {
      staticClass: "mb-0"
    }, [file.url ? _c('img', {
      staticClass: "h-24 w-full rounded-t-lg object-cover",
      attrs: {
        "src": file.url,
        "alt": ""
      }
    }) : _c('img', {
      attrs: {
        "src": require("@/assets/images/image-blank.png")
      }
    })]), _c('div', {
      staticClass: "p-3 flex justify-between"
    }, [_c('div', [_c('div', {
      staticClass: "text-neutral-500 font-medium text-sm ellipsis",
      attrs: {
        "title": file.title
      }
    }, [_vm._v(" " + _vm._s(file.title) + " ")]), _c('div', {
      staticClass: "text-neutral-400 text-xs"
    }, [_vm._v(" " + _vm._s(_vm.formatDate(file.createdDate)) + " ")])])])]);
  }), 0), _vm.totalRecords > 3 ? _c('div', {
    staticClass: "mt-5 flex justify-center"
  }, [_c('pagination', {
    attrs: {
      "currentPage": _vm.currentPage,
      "totalCount": _vm.totalRecords,
      "pageSize": _vm.pageSize
    },
    on: {
      "onPageChange": _vm.onPageChange
    }
  })], 1) : _vm._e()]) : _c('div', {
    staticClass: "py-10 flex justify-center items-center"
  }, [_c('div', {
    staticClass: "text-sm"
  }, [_vm._v("No result")])])]), _c('div', {
    staticClass: "flex justify-between items-center pt-5"
  }, [_vm.selectedFile.url ? _c('div', [_c('div', {
    staticClass: "w-36"
  }, [_c('img', {
    attrs: {
      "src": _vm.selectedFile.url,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "text-sm text-neutral-500 mt-2 font-medium"
  }, [_vm._v("Background Selected")])]) : _c('div', {
    staticClass: "text-sm text-neutral-500"
  }, [_vm._v("No File Selected")]), _c('Button', {
    attrs: {
      "buttonText": "Continue",
      "disabled": _vm.selectedFile.url ? false : true,
      "additionalClass": "h-11"
    },
    on: {
      "action": _vm.selectFile
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }