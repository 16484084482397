var render = function () {
  var _vm$options;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.label ? _c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v(_vm._s(_vm.label)), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLabelRequire,
      expression: "isLabelRequire"
    }],
    staticClass: "text-red"
  }, [_vm._v(" *")])]) : _vm._e(), _c('div', {
    staticClass: "dropdown text-sm",
    attrs: {
      "tabindex": _vm.tabindex
    },
    on: {
      "blur": function blur($event) {
        return _vm.handleBlur($event);
      }
    }
  }, [_c('div', {
    staticClass: "selected block p-3 text-neutral-700",
    class: [_vm.disabled ? 'cursor-not-allowed bg-gray-100 opacity-50' : 'cursor-pointer', _vm.disabledWhite ? 'cursor-not-allowed' : 'cursor-pointer', {
      open: _vm.open
    }],
    on: {
      "click": function click($event) {
        return _vm.toggleDropdown();
      }
    }
  }, [_vm.placeholderText ? _c('span', {
    staticClass: "placeholder truncate"
  }, [_vm._v(_vm._s(_vm.placeholderText))]) : [_vm.selected && _vm.optionLabel ? _c('span', {
    staticClass: "option-label pr-6",
    class: {
      uppercase: _vm.uppercase,
      capitalize: _vm.capitalize
    },
    attrs: {
      "title": _vm.selected[_vm.optionLabel] || ''
    }
  }, [_vm._v(_vm._s(_vm.selected[_vm.optionLabel] || ''))]) : _c('span', {
    staticClass: "option-label pr-6",
    class: {
      uppercase: _vm.uppercase,
      capitalize: _vm.capitalize
    },
    attrs: {
      "title": _vm.selected
    }
  }, [_vm._v(_vm._s(_vm.selected))])], _c('span', {
    staticClass: "drop-arrow absolute right-3 top-1/2 transform -translate-y-1/2 leading-0",
    class: {
      expanded: _vm.open
    }
  }, [_c('Chevron', {
    attrs: {
      "direction": "down",
      "width": "14",
      "height": "14"
    }
  })], 1)], 2), _c('div', {
    staticClass: "content py-2",
    class: {
      selectHide: !_vm.open
    },
    style: "max-height: ".concat(_vm.maxHeight ? _vm.maxHeight : '250px', ";")
  }, [_vm.enableSearch ? _c('div', {
    staticClass: "p-4 bg-neutral-200 shadow-border-b flex gap-2 items-center"
  }, [_c('span', {
    staticClass: "flex-shrink-0"
  }, [_c('Search', {
    attrs: {
      "width": "16",
      "height": "16"
    }
  })], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchKeyword,
      expression: "searchKeyword"
    }],
    staticClass: "bg-transparent outline-none border-none text-sm w-full",
    attrs: {
      "type": "text",
      "placeholder": _vm.searchPlaceholder
    },
    domProps: {
      "value": _vm.searchKeyword
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.search();
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.searchKeyword = $event.target.value;
      }
    }
  })]) : _vm._e(), !_vm.isLoadData ? _c('div', [((_vm$options = _vm.options) === null || _vm$options === void 0 ? void 0 : _vm$options.length) > 0 ? _vm._l(_vm.options, function (option, i) {
    return _c('div', {
      key: i,
      staticClass: "px-3 py-2 leading-tight text-neutral-700 hover:bg-yellow-primary cursor-pointer text-white",
      class: {
        'bg-neutral': option == _vm.selected
      },
      on: {
        "click": function click($event) {
          return _vm.selectItem(option);
        }
      }
    }, [_vm.optionLabel ? _c('span', {
      staticClass: "option-label",
      class: {
        uppercase: _vm.uppercase,
        capitalize: _vm.capitalize
      },
      attrs: {
        "title": option[_vm.optionLabel]
      }
    }, [_vm._v(_vm._s(option[_vm.optionLabel]))]) : _c('span', {
      staticClass: "option-label",
      class: {
        uppercase: _vm.uppercase,
        capitalize: _vm.capitalize
      },
      attrs: {
        "title": option
      }
    }, [_vm._v(_vm._s(option))])]);
  }) : _c('div', {
    staticClass: "text-sm text-white text-center",
    class: _vm.enableSearch ? 'my-3' : ''
  }, [_vm._v("- Data is not available -")])], 2) : _c('div', {
    staticClass: "py-7 px-auto"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large"
    }
  })], 1)])]), _vm.notes ? _c('div', {
    staticClass: "text-xs text-gray-500 mt-1"
  }, [_vm._v(_vm._s(_vm.notes))]) : _vm._e(), _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.error ? _c('div', {
    staticClass: "pl-3 text-system-error text-left mt-1 text-xs"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }