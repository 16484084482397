<template>
  <div>
    <div v-if="stillLoading" class="py-50 px-48">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else>
      <p class="text-2xl font-bold mb-4">Step 1: Select Background</p>
      <div class="border border-neutral-250 rounded-lg px-4 pt-4 pb-6 mb-5">
        <div class="flex items-center">
          <div class="w-1/2">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Search File (PNG)</label>
            <div class="relative">
              <TextField type="text" :enterKeyAction="getGallery" v-model="query" withIcon borderEnabled placeholder="Input file name here..." />
              <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                <Search color="#58595B" />
              </div>
            </div>
          </div>
          <div class="flex items-center h-full ml-3 mt-4">
            <Button buttonText="Search" type="secondary" @action="getGallery" additionalClass="h-11" />
          </div>
        </div>
      </div>
      <div class="border border-neutral-250 rounded-lg px-4 pt-6 pb-5">
        <div v-if="fileList.length > 0">
          <div class="grid grid-cols-3 gap-3">
            <div
              v-for="(file, index) in fileList"
              :key="index"
              class="cursor-pointer rounded-lg"
              :class="selectedFile.id === file.id ? 'border-yellow border-2' : 'border-neutral-250 border'"
              @click="clickFile(file)"
            >
              <div class="mb-0">
                <img :src="file.url" alt="" v-if="file.url" class="h-24 w-full rounded-t-lg object-cover" />
                <img src="@/assets/images/image-blank.png" v-else />
              </div>
              <div class="p-3 flex justify-between">
                <div>
                  <div class="text-neutral-500 font-medium text-sm ellipsis" :title="file.title">
                    {{ file.title }}
                  </div>
                  <div class="text-neutral-400 text-xs">
                    {{ formatDate(file.createdDate) }}
                  </div>
                </div>
                <!-- <div><ThreeDots /></div> -->
              </div>
            </div>
          </div>
          <div class="mt-5 flex justify-center" v-if="totalRecords > 3">
            <pagination :currentPage="currentPage" :totalCount="totalRecords" :pageSize="pageSize" @onPageChange="onPageChange" />
          </div>
        </div>

        <div class="py-10 flex justify-center items-center" v-else>
          <div class="text-sm">No result</div>
        </div>
      </div>
      <div class="flex justify-between items-center pt-5">
        <div v-if="selectedFile.url">
          <div class="w-36">
            <img :src="selectedFile.url" alt="" />
          </div>
          <div class="text-sm text-neutral-500 mt-2 font-medium">Background Selected</div>
        </div>

        <div class="text-sm text-neutral-500" v-else>No File Selected</div>
        <Button buttonText="Continue" :disabled="selectedFile.url ? false : true" @action="selectFile" additionalClass="h-11" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'choose-file',
  props: {
    templateUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileList: [],
      stillLoading: true,
      totalRecords: 0,
      currentPage: 1,
      pageSize: 3,
      query: '',
      selectedFile: {
        id: '',
        name: '',
        date: '',
        image: '',
        url: ''
      }
    }
  },
  created() {
    if (this.templateUrl) {
      this.selectedFile.url = this.templateUrl
    }
  },

  computed: {
    ...mapGetters('gallery', ['gallery']),
    clientId() {
      return localStorage.getItem('client')
    }
  },
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Close: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Close'),
    ThreeDots: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/ThreeDots'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/Pagination/Pagination')
  },
  mounted() {
    this.getGallery()
  },
  methods: {
    ...mapActions('gallery', ['GET_GALLERY', 'DELETE_FILE']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    sliceWords(str, limit) {
      if (str.length > limit) return str.slice(0, limit) + '...'
      return str
    },
    onPageChange(page) {
      this.currentPage = page
      this.getGallery()
    },
    formatDate(date) {
      return moment(date).local().format('DD MMM YYYY HH:mm')
    },
    selectFile() {
      this.$emit('selectFile', this.selectedFile.url)
    },
    clickFile(file) {
      this.selectedFile = file
    },
    getGallery() {
      this.stillLoading = true
      this.GET_GALLERY({
        params: {
          customerId: this.clientId,
          page: this.currentPage,
          limit: this.pageSize,
          mimeTypes: 'image/png',
          query: this.query
        }
      }).then((response) => {
        this.fileList = response.data.data
        this.totalRecords = response.data.pagination.total
        this.stillLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.25;
  height: 18px;
  word-break: break-all;
  margin-bottom: 4px;
}
</style>
